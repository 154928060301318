// import React from "react";
// import { useDispatch } from "react-redux";
// import { Boolbacks } from "constants/";

// import { createLearningMaterial } from "store/tutordashboard/action";

// /**
//  * A hook that is used in th content creation process by providing the current step, different states
//  * like has uploaded files and controllers for content creation,.
//  * @author Abdullah-Sajjad026
//  */

// export default function useA(
//   initialMaterial: LearningMaterial
// ) {
//   const dispatch: (action: any) => void = useDispatch();

//   const [currentStep, setCurrentStep] = React.useState(1);
//   const [learningMaterial, setLearningMaterial] =
//     React.useState(initialMaterial);

//   function createDraftLearningMaterial(
//     data: unknown,
//     currentAction: "add" | "update",
//     onSuccess: (lm: LearningMaterial) => void,
//     onFailure: Boolbacks["onFailure"]
//   ) {
//     dispatch(
//       createLearningMaterial(
//         data,
//         currentAction,
//         (lm: LearningMaterial) => {
//           setLearningMaterial(lm);
//           setCurrentStep(2);
//           onSuccess(lm);
//         },
//         onFailure
//       )
//     );
//   }

//   return {
//     currentStep,
//     learningMaterial,
//     createDraftLearningMaterial,
//   };
// }

import { ContentBuilderChapter } from "views/content-builder/content-builder-chapters";
import { create } from "zustand";
import { combine, devtools } from "zustand/middleware";

/**
 * A hook that is used in th content creation process by providing the current step, different states
 * like current step and controllers for content creation.
 * @author Abdullah-Sajjad-026
 */
const useContentBuildingHelper = create(
  devtools(
    combine<
      {
        currentStep: number;
        isPublishable: boolean;
        learningContent: LearningMaterial | {};
        materialContent: ContentBuilderChapter[] | [];
      },
      {
        setLearningContent: (content: LearningMaterial) => void;
        setCurrentStep: (step: number) => void;
        setIsPublishable: (isPublishable: boolean) => void;
        setMaterialContent: (content: ContentBuilderChapter[]) => void;
        setContentAndMoveStep: (
          content: LearningMaterial,
          nextStep?: number
        ) => void;
        resetHelper: () => void;
      }
    >(
      {
        /**
         * The current step of the content creation process
         */
        currentStep: 0,
        /**
         * The learning content that is being created
         */
        learningContent: {},

        materialContent: [],

        /**
         * A boolean that is used to check if the content is publishable or not
         */
        isPublishable: false,
      },
      (set, get) => ({
        /**
         * A function that is used to set the learning content
         * @param content - The content to be set as the learning content
         */
        setLearningContent: (content: LearningMaterial) =>
          set({ learningContent: content }),

        setMaterialContent: (content: ContentBuilderChapter[]) =>
          set({ materialContent: content }),

        /**
         * A function that is used to set the current step
         * @param step - The step to be set as the current step
         */
        setCurrentStep: (step: number) => set({ currentStep: step }),

        /**
         *  A function that is used to mark the content as publishable or not
         */
        setIsPublishable: (isPublishable: boolean) => set({ isPublishable }),

        setContentAndMoveStep: (
          content: LearningMaterial,
          nextStep?: number
        ) => {
          set({
            learningContent: content,
            ...(!!nextStep ? { currentStep: nextStep } : {}),
          });
        },

        resetHelper: () =>
          set({ currentStep: 0, learningContent: {}, isPublishable: false }),
      })
    )
  )
);

export default useContentBuildingHelper;
