import React from "react";
import userIdleLargeDoodle from "assets/images/doodles/@x2/doodle.png";
import { Card, Col, Row } from "react-bootstrap";
import { SassyCard } from "components/cards";
import { RTutorTutoring } from "constants/tutor-views-routes";
import { Link } from "react-router-dom";

import "./no-upcoming-session.scss";

const NoUpComingSessions = () => {
  return (
    <div className="no-upcoming-session-rounded">
      <SassyCard className="h-100 position-relative">
        <Row className="g-2 g-md-4 justify-content-center w-full m-0">
          <Col>
            <SassyCard.Body
              style={{ zIndex: 1, marginBlock: "2rem" }}
              className="align-items-center align-items-md-center"
            >
              <img alt="" src={userIdleLargeDoodle} className="w-25 h-25" />
              <p className="no-upcoming-session-subheading">
                You don't have any upcoming sessions
              </p>
              <Card.Text className="m-0 text-center text-md-center no-upcoming-session-subheading-2">
                Note: customer feedback is a great way to enhance <br /> the
                services you provide.
              </Card.Text>
              <Link
                className="btn btn-secondary"
                data-tourstep="showManageServicesCta"
                to={`${RTutorTutoring.newSession}`}
              >
                Schedule Session
              </Link>
            </SassyCard.Body>
          </Col>
        </Row>
      </SassyCard>
    </div>
  );
};

export default NoUpComingSessions;
