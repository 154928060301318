import { getEnvVar } from "utils/utils-env";
import { DEFAULT_TUTOR_PAYOUT_TRANSFER_DAYS } from "./borderless";
import {
  DEFAULT_TUTOR_MAX_SKILLS,
  DEFAULT_TUTOR_MAX_SESSIONS,
  DEFAULT_MAX_BIO_CHARACTERS,
  DEFAULT_MAX_PAYMENT_METHODS,
  DEFAULT_TUTOR_MIN_SESSION_FEE,
  DEFAULT_TUTOR_MAX_SESSION_FEE,
  DEFAULT_FETCH_NOTIFICATIONS_INTERVAL,
  DEFAULT_TUTOR_MAX_QUALIFICATIONS,
  DEFAULT_TUTOR_MAX_CERTIFICATES,
  DEFAULT_TUTOR_MAX_EXPERIENCES,
  DEFAULT_TUTOR_MAX_SUBJECTS,
  DEFAULT_MAX_MATERIAL_FILE_SIZE,
  DEFAULT_MAX_BANKS,
  DEFAULT_LEARNER_MAX_CURRICULUMS,
  DEFAULT_LEARNER_MAX_GRADES,
  DEFAULT_LEARNER_MAX_INTERESTS,
  DEFAULT_MAX_MATERIAL_VIDEO_SIZE,
  DEFAULT_MAX_MATERIAL_PREVIEW_SIZE,
  DEFAULT_MAX_MATERIAL_THUMBNAIL_SIZE,
  DEFAULT_POPULAR_MATERIALS_PER_PAGE,
  DEFAULT_LIMIT_DESCRIPTIONS,
  DEFAULT_LIMIT_TITLES,
  DEFAULT_LIMIT_NAME,
  DEFAULT_MAX_BUNDLES_PER_SESSION,
  DEFAULT_MAX_ENTITIES_PER_SESSION,
  DEFAULT_LEARNER_MAX_ASSIGNMENTS,
  DEFAULT_LEARNER_MAX_LANGUAGES,
  DEFAULT_MAX_WITHDRAW_LIMIT,
  DEFAULT_MIN_WITHDRAW_LIMIT,
  DEFAULT_MAX_MATERIAL_CHAPTERS,
} from "./integers";

export const LEARNING_MATERIAL_DETAILS_URL =
  process.env.REACT_APP_LEARNING_MATERIALS_DETAILS_URL;
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
export const API_MAIN_URL = process.env.REACT_APP_USER_SERVICE_URL;
export const VIDEO_TOOL_URL = process.env.REACT_APP_VIDEO_CALL_TOOL;
export const CONTENT_TOOL_URL = process.env.REACT_APP_CONTENT_BUILDER_URL;
export const LEARNING_MATERIALS_SEARCH =
  process.env.REACT_APP_LEARNING_MATERIALS_SEARCH_URL;

// Notifications.
export const FETCH_NOTIFICATIONS_INTERVAL =
  process.env.REACT_APP_FETCH_NOTIFICATIONS_INTERVAL ||
  DEFAULT_FETCH_NOTIFICATIONS_INTERVAL;

// Profile.
export const MAX_BIO_CHARACTERS =
  parseInt(process.env.REACT_APP_MAX_BIO_CHARACTERS) ||
  DEFAULT_MAX_BIO_CHARACTERS;
export const TUTOR_MAX_QUALIFICATIONS =
  parseInt(process.env.REACT_APP_TUTOR_MAX_QUALIFICATIONS) ||
  DEFAULT_TUTOR_MAX_QUALIFICATIONS;
export const TUTOR_MAX_SKILLS =
  parseInt(process.env.REACT_APP_TUTOR_MAX_SKILLS) || DEFAULT_TUTOR_MAX_SKILLS;
export const TUTOR_MAX_CERTIFICATES =
  parseInt(process.env.REACT_APP_TUTOR_MAX_CERTIFICATES) ||
  DEFAULT_TUTOR_MAX_CERTIFICATES;
export const TUTOR_MAX_EXPERIENCES =
  parseInt(process.env.REACT_APP_TUTOR_MAX_EXPERIENCES) ||
  DEFAULT_TUTOR_MAX_EXPERIENCES;
export const TUTOR_MAX_SUBJECTS =
  parseInt(process.env.REACT_APP_TUTOR_MAX_SUBJECTS) ||
  DEFAULT_TUTOR_MAX_SUBJECTS;
export const TUTOR_MAX_LANGUAGES =
  parseInt(process.env.REACT_APP_TUTOR_MAX_LANGUAGES) ||
  DEFAULT_TUTOR_MAX_SUBJECTS;

// Learner's Limitations.
export const LEARNER_MAX_ASSIGNMENTS = parseInt(
  process.env.REACT_APP_LEARNER_MAX_ASSIGNMENTS ||
    DEFAULT_LEARNER_MAX_ASSIGNMENTS
);
export const LEARNER_MAX_CURRICULUMS = parseInt(
  process.env.REACT_APP_LEARNER_MAX_CURRICULUMS ||
    DEFAULT_LEARNER_MAX_CURRICULUMS
);
export const LEARNER_MAX_GRADES = parseInt(
  process.env.REACT_APP_LEARNER_MAX_GRADES || DEFAULT_LEARNER_MAX_GRADES
);
export const LEARNER_MAX_INTERESTS = parseInt(
  process.env.REACT_APP_LEARNER_MAX_INTERESTS || DEFAULT_LEARNER_MAX_INTERESTS
);
export const LEARNER_MAX_LANGUAGES = parseInt(
  process.env.REACT_APP_LEARNER_MAX_LANGUAGES || DEFAULT_LEARNER_MAX_LANGUAGES
);

// Tutoring.
export const TUTOR_MAX_SESSIONS = parseInt(
  process.env.REACT_APP_TUTOR_MAX_SESSIONS || DEFAULT_TUTOR_MAX_SESSIONS
);
export const TUTOR_MIN_SESSION_FEE = parseInt(
  process.env.REACT_APP_TUTOR_MIN_SESSION_FEE || DEFAULT_TUTOR_MIN_SESSION_FEE
);
export const TUTOR_MAX_SESSION_FEE = parseInt(
  process.env.REACT_APP_TUTOR_MAX_SESSION_FEE || DEFAULT_TUTOR_MAX_SESSION_FEE
);
export const MAX_BUNDLES_PER_SESSION = parseInt(
  process.env.REACT_APP_MAX_BUNDLES_PER_SESSION ||
    DEFAULT_MAX_BUNDLES_PER_SESSION
);
export const MAX_ENTITIES_PER_SESSION = parseInt(
  process.env.REACT_APP_MAX_ENTITIES_PER_SESSION ||
    DEFAULT_MAX_ENTITIES_PER_SESSION
);

// Payments.
export const MAX_PAYMENT_METHODS =
  process.env.REACT_APP_MAX_PAYMENT_METHODS || DEFAULT_MAX_PAYMENT_METHODS;
export const MAX_BANKS = process.env.REACT_APP_MAX_BANKS || DEFAULT_MAX_BANKS;
export const MAX_WITHDRAW_LIMIT =
  process.env.REACT_APP_MAX_WITHDRAW_LIMIT || DEFAULT_MAX_WITHDRAW_LIMIT;
export const MIN_WITHDRAW_LIMIT =
  process.env.REACT_APP_MIN_WITHDRAW_LIMIT || DEFAULT_MIN_WITHDRAW_LIMIT;

export const PAYOUT_TRANSFER_DAYS =
  process.env.REACT_APP_TUTOR_PAYOUT_TRANSFER_DAYS ||
  DEFAULT_TUTOR_PAYOUT_TRANSFER_DAYS;

// Learning Material.
export const MAX_MATERIAL_FILE_SIZE =
  process.env.REACT_APP_MAX_MATERIAL_FILE_SIZE ||
  DEFAULT_MAX_MATERIAL_FILE_SIZE;
export const MAX_MATERIAL_VIDEO_SIZE =
  process.env.REACT_APP_MAX_MATERIAL_VIDEO_SIZE ||
  DEFAULT_MAX_MATERIAL_VIDEO_SIZE;
export const MAX_MATERIAL_PREVIEW_SIZE =
  process.env.REACT_APP_MAX_MATERIAL_PREVIEW_SIZE ||
  DEFAULT_MAX_MATERIAL_PREVIEW_SIZE;
export const POPULAR_MATERIALS_PER_PAGE =
  process.env.REACT_APP_POPULAR_MATERIALS_PER_PAGE ||
  DEFAULT_POPULAR_MATERIALS_PER_PAGE;
export const MAX_MATERIAL_THUMBNAIL_SIZE =
  process.env.REACT_APP_MAX_MATERIAL_THUMBNAIL_SIZE ||
  DEFAULT_MAX_MATERIAL_THUMBNAIL_SIZE;
export const MAX_MATERIAL_CHAPTERS =
  process.env.REACT_APP_MAX_MATERIAL_CHAPTERS || DEFAULT_MAX_MATERIAL_CHAPTERS;

export const MAX_LIMIT_DESCRIPTIONS =
  process.env.REACT_APP_MAX_LIMIT_DESCRIPTIONS || DEFAULT_LIMIT_DESCRIPTIONS;

export const MAX_LIMIT_TITLES =
  process.env.REACT_APP_MAX_LIMIT_TITLES || DEFAULT_LIMIT_TITLES;

export const MAX_LIMIT_NAME =
  process.env.REACT_APP_MAX_LIMIT_NAME || DEFAULT_LIMIT_NAME;

// Phone Number.
export const OTP_LENGTH = parseInt(process.env.OTP_LENGTH || "5");

// Sessions.
export const GRADES_PER_SESSION = parseInt(
  process.env.REACT_APP_GRADES_PER_SESSION || "5"
);
export const SUBJECTS_PER_SESSION = parseInt(
  process.env.REACT_APP_SUBJECTS_PER_SESSION || "1"
);
export const CURRICULUMS_PER_SESSION = parseInt(
  process.env.REACT_APP_CURRICULUMS_PER_SESSION || "15"
);

// External Libraries
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;
export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;
