import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import { ContentBuilderChapter } from "..";
import { RequestState } from "constants/";
import { Loader, SassyAlert } from "components";
import { IconPencil, IconTrash } from "@tabler/icons";

export default function ListChaptersModal({
  isOpen,
  onClose,
  chaptersBag,
  onAddChapterListener,
  onDeleteChapterListener,
  onEditChapterListener,
}: {
  isOpen: boolean;
  onClose: () => void;
  chaptersBag: {
    chapters: ContentBuilderChapter[];
    number_of_pages: number;
    requestState: RequestState;
  };
  onAddChapterListener: () => void;
  onDeleteChapterListener: (chapterId: number) => void;
  onEditChapterListener: (meta: ContentBuilderChapter) => void;
}) {
  return (
    <Modal centered show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Chapters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button className="w-100 mb-2" onClick={onAddChapterListener}>
          Add Chapter
        </Button>

        {chaptersBag.requestState === "loading" ? (
          <Loader isInline />
        ) : chaptersBag.requestState === "erred" ? (
          <SassyAlert
            title="Error"
            description="Something went wrong while fetching chapters."
            color="danger"
          />
        ) : chaptersBag.chapters.length === 0 ? (
          <SassyAlert
            title="No Chapters"
            description="No chapters have been added yet."
            color="info"
          />
        ) : (
          <div className="mt-4 d-flex flex-column gap-3">
            {chaptersBag.chapters.map((chapter) => (
              <div className="d-flex justify-content-between align-items-center bg-white p-3 rounded shadow-sm border">
                <div className="d-flex gap-2">
                  <img
                    src={chapter.thumbnail}
                    alt="Chapter Thumbnail"
                    className="rounded-3"
                    style={{ width: 50, height: 50 }}
                  />

                  <div className="d-flex flex-column">
                    <h5
                      className="mb-0 fs-6 text-truncate"
                      style={{
                        maxWidth: 200,
                      }}
                    >
                      {chapter.title}
                    </h5>
                    <p
                      className="mb-0 d-inline-block text-truncate fs-6"
                      style={{
                        maxWidth: 200,
                      }}
                    >
                      {chapter.description}
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <button
                    role="tooltip"
                    aria-label="Edit this chapter metadata"
                    data-microtip-position="bottom"
                    className="btn border rounded bg-gray-300 fs-5"
                    onClick={() => {
                      onEditChapterListener(chapter);
                    }}
                  >
                    <IconPencil size={20} />
                  </button>
                  <button
                    role="tooltip"
                    aria-label="Delete this chapter"
                    data-microtip-position="bottom"
                    className="btn border rounded bg-gray-300 fs-5"
                    onClick={() => {
                      onDeleteChapterListener(chapter.id);
                    }}
                  >
                    <IconTrash size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
