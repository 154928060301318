import React from "react";

import {
  API_MAIN_URL,
  MATERIAL_TYPE_PAGES,
  MAX_MATERIAL_CHAPTERS,
  RequestState,
} from "constants/";
import ContentBuilderChaptersService from "service/content-builder/content-builder-chapters-service";
import { useContentBuildingHelper } from "hooks/content-builder";
import useToast from "hooks/use-toast";
import { ContentBuilderChapter, ContentBuilderPage } from ".";
import { useContentFiles } from "hooks";
import { apiService } from "service";
import { getAuthHeaders } from "utils";
import { tutorRoutes } from "router/routes";
import { useNavigate } from "react-router-dom";

type ComponentProps = {
  children: (_: ChaptersProviderControllers) => JSX.Element;
};

export type ChaptersProviderControllers = {
  onContinue: () => void;
  chaptersBag: {
    chapters: ContentBuilderChapter[];
    number_of_pages: number;
    requestState: RequestState;
  };
  materialContentBag: {
    requestState: RequestState;
    content:
      | ContentBuilderPage[]
      | ReturnType<typeof useContentFiles>["state"]["data"];
  };

  chapterHandlers: {
    createChapter: ({
      values,
      onSuccess,
    }: {
      values: ContentBuilderChapter & { thumbnail: File };
      onSuccess?: Function;
    }) => void;
    updateChapter: ({
      values,
      onSuccess,
    }: {
      values: ContentBuilderChapter & { thumbnail: File };
      onSuccess?: Function;
    }) => void;
    deleteChapter: (chapterId: number) => void;
    manageChapterContent: ({
      chapter_id,
      content_id,
      action,
    }: {
      chapter_id: number;
      content_id: number;
      action: "add" | "delete";
    }) => void;
  };
};

// A bit of hurried code. Will refactor later if needed be. Could be better split and organized.

export default function ContentBuilderChaptersProvider({
  children,
}: ComponentProps): JSX.Element {
  // Hooks
  const toast = useToast();
  const navigate = useNavigate();

  // Services
  const chaptersService = new ContentBuilderChaptersService();

  // Context
  const { learningContent } = useContentBuildingHelper();

  const learningMaterial = learningContent as LearningMaterial;

  // Chapters State
  const [chaptersBag, setChaptersBag] = React.useState<
    ChaptersProviderControllers["chaptersBag"]
  >({
    chapters: [],
    number_of_pages: 0,
    requestState: "idle",
  });

  // Files State (if material is of type files). Auto fetches files of the material.
  const { state: filesBag } = useContentFiles(learningMaterial.id!);

  // Material Content State. Holds the pages or files of the material.
  const [materialContentBag, setMaterialContentBag] = React.useState<
    ChaptersProviderControllers["materialContentBag"]
  >({
    requestState: "idle",
    content: [],
  });

  React.useEffect(() => {
    // resetting chapters bag state
    setChaptersBag((prevState) => ({ ...prevState, requestState: "loading" }));

    // Fetching the chapters for this learning material
    chaptersService.getChapters({
      id: learningMaterial.id!,
      onSuccess: ({
        data,
        number_of_pages,
      }: {
        data: ContentBuilderChapter[];
        number_of_pages: number;
      }) => {
        setChaptersBag((prevState) => ({
          ...prevState,
          requestState: "loaded",
          chapters: data,
          number_of_pages,
        }));
      },
      onFailure: ({ message }) => {
        setChaptersBag((prevState) => ({
          ...prevState,
          requestState: "erred",
        }));
        toast.error(message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningMaterial.id]);

  React.useEffect(() => {
    // Fetching the pages for this learning material (if material is of type pages)
    if (learningMaterial?.content_type === MATERIAL_TYPE_PAGES) {
      setMaterialContentBag((prev) => ({ ...prev, requestState: "loading" }));

      apiService.get({
        onSuccess: ({ data }: { data: ContentBuilderPage[] }) => {
          setMaterialContentBag({
            requestState: "loaded",
            content: data,
          });
        },
        onFailure: () => {
          toast.error(
            "There was an error while your learning material's pages"
          );

          setMaterialContentBag((prev) => ({ ...prev, requestState: "erred" }));
        },
        headers: getAuthHeaders(),
        url: `${API_MAIN_URL}/contentbuilder/learning-material/fetch-pages/${learningMaterial.id}?order=asc`,
      });
    } else {
      // if material is of type files, update the materialContentBag with the filesBag
      // whenever filesBag changes or material changes
      setMaterialContentBag({
        requestState: filesBag.status,
        content: filesBag.data,
      });
    }

    // whenever filesBag changes, update the materialContentBag if material is of type files
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningMaterial.id, filesBag.status]);

  /*
    ================= Chapters Handlers =================
  */

  /**
   *  Creates a new chapter for the current material and updates the chapters bag state.
   */
  const createChapter: ChaptersProviderControllers["chapterHandlers"]["createChapter"] =
    ({ values, onSuccess }) => {
      // Checking if limit of chapters has been reached
      if (
        chaptersBag.chapters.length >= parseInt(MAX_MATERIAL_CHAPTERS as string)
      ) {
        toast.info(
          `You can only create ${MAX_MATERIAL_CHAPTERS} chapters per material`
        );
        return;
      }

      toast.loading("Creating chapter ...");

      chaptersService.createChapter({
        ...values,
        content_id: learningMaterial.id!,
        onSuccess: ({
          data: newChapters,
        }: {
          data: ContentBuilderChapter[];
        }) => {
          toast.success("Chapter created successfully");

          setChaptersBag({
            chapters: newChapters,
            requestState: "loaded",
            number_of_pages: newChapters.length,
          });

          onSuccess?.();
        },

        onFailure: (error) => {
          console.log(error);

          toast.error(error.message);
        },
      });
    };

  /**
   * Deletes a chapter from the current material and updates the chapters bag state.
   * @param chapterId The id of the chapter to be deleted.
   * @see ContentBuilderChaptersService.deleteChapter
   */

  const deleteChapter: ChaptersProviderControllers["chapterHandlers"]["deleteChapter"] =
    (chapterId: number) => {
      toast.loading();

      chaptersService.deleteChapter({
        chapterId,
        onSuccess: () => {
          toast.success("Chapter deleted successfully");

          // updating the chapters bag state
          setChaptersBag({
            chapters: chaptersBag.chapters.filter(
              (chapter) => chapterId !== chapter.id
            ),
            requestState: "loaded",
            number_of_pages: chaptersBag.chapters.length - 1,
          });

          // updating the content's chapter_id in the materialContentBag
          setMaterialContentBag((prev) => ({
            ...prev,
            content: prev.content.map((content) => {
              if (content.chapter_id === chapterId) {
                return {
                  ...content,
                  chapter_id: null,
                };
              }
              return content;
            }),
          }));
        },
        onFailure: (error) => {
          console.log(error);

          toast.error(error.message);
        },
      });
    };

  /**
   * Updates a chapter's meta from the current material and updates the chapters bag state.
   */
  const updateChapter: ChaptersProviderControllers["chapterHandlers"]["updateChapter"] =
    ({ values, onSuccess }) => {
      toast.loading("Updating chapter ...");

      chaptersService.updateChapter({
        ...values,
        chapter_id: values.id,
        onSuccess: ({
          data: updatedChapters,
        }: {
          data: ContentBuilderChapter[];
        }) => {
          setChaptersBag((prev) => ({
            ...prev,
            chapters: updatedChapters,
          }));

          toast.success("Chapter updated successfully");

          onSuccess?.();
        },
        onFailure: (error) => {
          console.log(error);

          toast.error(error.message);
        },
      });
    };

  /**
   * Assigns a chapter to a content (page or file).
   */

  const manageChapterContent: ChaptersProviderControllers["chapterHandlers"]["manageChapterContent"] =
    ({ chapter_id, content_id, action }) => {
      toast.loading("Assigning chapter to content ...");
      chaptersService.manageChapterContent({
        action,
        chapter_id,
        content_id,
        material_id: learningMaterial.id!,
        onSuccess: ({ data }: { data: unknown }) => {
          toast.success(
            `Content ${action === "add" ? "added to" : "removed from"} chapter`
          );
          // updating the content's chapter_id in the materialContentBag
          setMaterialContentBag((prev) => ({
            ...prev,
            content: prev.content.map((content) => {
              if (content.id === content_id) {
                return {
                  ...content,
                  chapter_id: action === "add" ? chapter_id : null,
                };
              }
              return content;
            }),
          }));
        },
        onFailure: (error) => {
          console.log(error);

          toast.error(
            typeof error.message === "object"
              ? Object.values(error.message).join("\n")
              : error.message
          );
        },
      });
    };

  const onContinue = () => {
    navigate(`${tutorRoutes.createMaterials.url}?id=${learningMaterial.id}`);
  };

  return children({
    onContinue,
    chaptersBag,
    chapterHandlers: {
      createChapter,
      deleteChapter,
      updateChapter,
      manageChapterContent,
    },
    materialContentBag,
  });
}
