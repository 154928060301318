import React from "react";

// Views.
import {
  TutorDashboard,
  TutorProfileView,
  // Reviews
  TutorDashboardReviews,
  // Tutoring-Tutor
  TutoringTutor,
  TutoringTutorBundle,
  TutoringTutorLanding,
  TutoringTutorSchedule,
  TutoringTutorServices,
  TutoringTutorNewSession,
  TutoringTutorAvailability,
  TutoringTutorSessionRequest,
  TutoringTutorNewSessionStudentActions,
  // Content Builder.
  ContentBuilder,
  ContentBuilderManage,
  CreateContentWrapper,
  ContentBuilderCreate,
  ContentBuilderUpload,
  ContentBuilderDecision,
  //Earnings-Tutor
  Earnings,
  EarningsAddBank,
  EarningsDashboard,
  // Transactions
  // Payments [Transactions & Earnings combined.]
  PaymentsHistoryView,
} from "views";
import TutorEarningsWithdrawals from "views/earnings/earnings-withdrawals";
import OnboardingTutorV2 from "views/onboarding-v2/tutor";
import {
  Terms as OnboardingTutorTerms,
  Basics as OnboardingTutorBasics,
  Service as OnboardingTutorService,
  Availability as OnboardingTutorAvailability,
} from "views/onboarding-v2/tutor/children";
import TutorDashboardV1 from "views/dashboard/dashboard-tutor-v1";
import TutoringTutorSessions from "views/tutoring/tutoring-tutor/tutoring-tutor-sessions";
import ContentBuilderChapters from "views/content-builder/content-builder-chapters/content-builder-chapters";

// Components.
import { Route } from "react-router-dom";
import { Navigator } from "components";

// Layouts
import { VerticalLayout } from "layout";

// Modules.
import getPhoneNumberRoutes from "./_get-phone-number-routes";
import { RawPhoneNumberContextProvider } from "context/phone-number";
import { tutorRoutes as routes } from "./routes";

export default function getTutorRoutes(navigate) {
  return [
    // Onboarding V2
    <Route {...routes.onboarding} element={<OnboardingTutorV2 />}>
      <Route {...routes.onboardingBasics} element={<OnboardingTutorBasics />} />
      <Route
        {...routes.onboardingAvailability}
        element={<OnboardingTutorAvailability />}
      />
      <Route
        {...routes.onboardingService}
        element={<OnboardingTutorService />}
      />
      <Route {...routes.onboardingTerms} element={<OnboardingTutorTerms />} />
    </Route>,
    <Route element={<VerticalLayout />}>
      <Route element={<Navigator />}>
        {/* Routes for boarded tutors. */}
        <Route element={<RawPhoneNumberContextProvider />}>
          <Route path="/profile" element={<TutorProfileView />}>
            {getPhoneNumberRoutes(navigate, "/profile").map((_) => _)}
          </Route>
        </Route>
        <Route path="/dashboard" element={<TutorDashboard />} />
        <Route path="/dashboard-v1" element={<TutorDashboardV1 />} />

        <Route path="/1-1-tutoring" element={<TutoringTutor />}>
          <Route index element={<TutoringTutorLanding />} />
          <Route {...routes.services} element={<TutoringTutorServices />} />
          <Route {...routes.editServices} element={<TutoringTutorBundle />} />
          <Route {...routes.createServices} element={<TutoringTutorBundle />} />
          <Route path="availability" element={<TutoringTutorAvailability />} />

          <Route path="sessions/new">
            <Route
              index
              element={
                <TutoringTutorNewSession
                  isLearnerSelected={false}
                  isBundleSelected={false}
                />
              }
            />
            <Route
              path="student/:student_id"
              element={<TutoringTutorNewSession isLearnerSelected={true} />}
            />
            <Route
              path="student/:student_id/bundle/:bundle_id"
              element={
                <TutoringTutorNewSession
                  isLearnerSelected={true}
                  isBundleSelected={true}
                />
              }
            />
          </Route>
          <Route path="sessions" element={<TutoringTutorSessions />}>
            <Route index element={<TutoringTutorSchedule />} />
            <Route
              path="details/:session_id/req/:request_id"
              element={<TutoringTutorSessionRequest />}
            />
          </Route>

          {/* Deprecated Routes */}
          <Route path="calendar" element={<TutoringTutorSchedule />} />
          <Route path="session-request">
            <Route
              path="details/:session_id/req/:request_id"
              element={<TutoringTutorSessionRequest />}
            />
          </Route>
          <Route path="new-session" element={<TutoringTutorNewSession />}>
            <Route index element={<TutoringTutorNewSessionStudentActions />} />
            <Route
              path="student/:student_id/bundle/:bundle_id"
              element={<TutoringTutorNewSessionStudentActions />}
            />
          </Route>
          {/* Deprecated Routes End */}
        </Route>
        {/* Tutor's earning-routes start here. */}
        <Route path="/earnings" element={<Earnings />}>
          <Route index element={<EarningsDashboard />} />
          <Route path="withdrawals">
            <Route index element={<TutorEarningsWithdrawals />} />
            <Route path="page/:page" element={<TutorEarningsWithdrawals />} />
          </Route>
          <Route path="add-bank" element={<EarningsAddBank />} />
          <Route path=":type" element={<PaymentsHistoryView />} />
          <Route path=":type/page/:page" element={<PaymentsHistoryView />} />
        </Route>
        {/* Tutor's earning-routes end here. */}
        {/* Tutor's content builder-routes start here. */}
        <Route {...routes.materials} element={<ContentBuilder />} />
        <Route
          {...routes.publishedMaterials}
          element={<ContentBuilderManage />}
        />
        <Route {...routes.draftMaterials} element={<ContentBuilderManage />} />

        <Route element={<CreateContentWrapper />}>
          <Route
            {...routes.createMaterials}
            element={<ContentBuilderCreate />}
          />
          <Route
            {...routes.chooseMaterialType}
            element={<ContentBuilderDecision />}
          />
          <Route
            {...routes.uploadMaterials}
            element={<ContentBuilderUpload />}
          />

          <Route
            {...routes.organizeMaterial}
            element={<ContentBuilderChapters />}
          />
        </Route>
        {/* <Route
          {...routes.setMaterialPrice}
          element={<ViewContentBuilderPricing />}
        /> */}
        {/* Tutor's content builder-routes end here. */}
        <Route path="/reviews" element={<TutorDashboardReviews />} />
      </Route>
    </Route>,
  ];
}
