import React from "react";
import * as Yup from "yup";
import { FileSchema } from "modules/schemas";
import { TWO_MB, IMAGE_FILE_TYPES } from "constants/";
import { Field, Formik, Form as FormikForm } from "formik";
import { Modal, Form } from "react-bootstrap";
import { Button } from "reactstrap";

const INITIAL_VALUES = {
  action: "add",
  title: "",
  description: "",
  thumbnail: undefined,
};

const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  // @ts-ignore
  thumbnail: new FileSchema({
    maxSize: TWO_MB,
    allowedFormats: IMAGE_FILE_TYPES,
    allowString: true,
    fileName: "thumbnail",
    isRequired: true,
  }),
});

type ChapterFormProps = {
  action?: "add" | "edit";
  isOpen: boolean;
  onClose: () => void;

  initialValues?: unknown;
  onSubmit: (values: unknown) => void;
};

export default function ChapterFormModal({
  action = "add",
  isOpen,
  onClose,

  initialValues = INITIAL_VALUES,
  onSubmit,
}: ChapterFormProps) {
  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{action === "add" ? "Add" : "Edit"} Chapter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          // @ts-ignore
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange, values, errors, touched, setFieldValue }) => (
            <FormikForm>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Field
                  name="title"
                  as={Form.Control}
                  isInvalid={!!(touched.title && errors.title)}
                />
                {touched.title && errors.title && (
                  <Form.Control.Feedback type="invalid">
                    {errors.title as string}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Field
                  name="description"
                  as={Form.Control}
                  isInvalid={!!(touched.description && errors.description)}
                />
                {touched.description && errors.description && (
                  <Form.Control.Feedback type="invalid">
                    {errors.description as string}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Thumbnail</Form.Label>
                <Form.Control
                  name="thumbnail"
                  type="file"
                  onChange={(event) => {
                    // @ts-ignore
                    setFieldValue("thumbnail", event.target.files?.[0]);
                  }}
                  isInvalid={!!(touched.thumbnail && errors.thumbnail)}
                />
                {touched.thumbnail && errors.thumbnail && (
                  <Form.Control.Feedback type="invalid">
                    {errors.thumbnail as string}
                  </Form.Control.Feedback>
                )}

                {values.thumbnail && typeof values.thumbnail === "string" && (
                  // anchor tag with recent thumbnail
                  <a
                    href={values.thumbnail}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block mt-2"
                  >
                    Recent Thumbnail (click to open)
                  </a>
                )}
              </Form.Group>

              <div className="hstack gap-2 mt-3">
                <Button
                  color="secondary"
                  outline
                  onClick={onClose}
                  type="reset"
                >
                  Cancel
                </Button>
                <Button color="secondary" type="submit">
                  {action === "add" ? "Add" : "Edit"} Chapter
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
