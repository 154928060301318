// Memory Sizes.
export const ONE_MB = 1024 * 1024;
export const TWO_MB = ONE_MB * 2;
export const THREE_MB = ONE_MB * 3;
export const FOUR_MB = ONE_MB * 4;
export const FIVE_MB = ONE_MB * 5;

// Tutoring.
export const DEFAULT_SESSION_LENGTH = 45;
export const DEFAULT_MEETING_LATE_SPAN = 30;
export const DEFAULT_TUTOR_MAX_SESSIONS = 30;
export const DEFAULT_TUTOR_MIN_SESSION_FEE = 15;
export const DEFAULT_SESSIONS_PER_PAGE = 10;
export const DEFAULT_TUTOR_ACTIVITIES_PER_PAGE = 4;
export const DEFAULT_TUTOR_MAX_SESSION_FEE = 1000;
export const DEFAULT_ORDERED_BUNDLES_PER_PAGE = 4;
export const DEFAULT_CONNECTED_USERS_PER_PAGE = 6;
export const DEFAULT_LEARNER_PREVIOUS_SESSIONS_PER_PAGE = 4;
export const DEFAULT_MAX_BUNDLES_PER_SESSION = 7;
export const DEFAULT_MAX_ENTITIES_PER_SESSION = 15;
export const DEFAULT_TUTOR_MIN_BOOKING_HOURS = 6;
export const DEFAULT_TUTOR_MAX_ADVANCE_BOOKING_WEEKS = 4;
export const DEFAULT_MAX_AVAILABILITY_SLOTS_PER_DAY = 3;

// Payments.
export const DEFAULT_MAX_BANKS = 3;
export const DEFAULT_MAX_PAYMENT_METHODS = 3;
export const DEFAULT_MAX_WITHDRAW_LIMIT = 10000;
export const DEFAULT_MIN_WITHDRAW_LIMIT = 50;

// Profile.
export const DEFAULT_MAX_BIO_CHARACTERS = 1000;
export const DEFAULT_TUTOR_MAX_QUALIFICATIONS = 5;
export const DEFAULT_TUTOR_MAX_EXPERIENCES = 5;
export const DEFAULT_TUTOR_MAX_LANGUAGES = 5;
export const DEFAULT_TUTOR_MAX_SKILLS = 5;
export const DEFAULT_TUTOR_MAX_CERTIFICATES = 5;
export const DEFAULT_TUTOR_MAX_SUBJECTS = 5;
export const DEFAULT_LEARNER_AGE_YEARS_DIFF = 4;

export const DEFAULT_TUTOR_REVIEWS = 6;

// Learner's Limitations.
export const DEFAULT_LEARNER_MAX_GRADES = 1;
export const DEFAULT_LEARNER_MAX_LANGUAGES = 5;
export const DEFAULT_LEARNER_MAX_INTERESTS = 5;
export const DEFAULT_LEARNER_MAX_CURRICULUMS = 1;
export const DEFAULT_LEARNER_MAX_ASSIGNMENTS = 1;

// Learning Materials.
export const MATERIAL_TYPE_FILES = 0;
export const MATERIAL_TYPE_PAGES = 1;
export const MATERIAL_TYPE_AMBIGUOUS = 2;
export const DEFAULT_POPULAR_MATERIALS_PER_PAGE = 4;
export const DEFAULT_LEARNING_MATERIALS_PER_PAGE = 10;
export const DEFAULT_MAX_MATERIAL_FILE_SIZE = 10485760;
export const DEFAULT_MAX_MATERIAL_VIDEO_SIZE = FIVE_MB;
export const DEFAULT_MAX_MATERIAL_PREVIEW_SIZE = 2097152;
export const DEFAULT_MAX_MATERIAL_THUMBNAIL_SIZE = 1048576;
export const DEFAULT_MAX_MATERIAL_CHAPTERS = 20;

// Notifications.
export const DEFAULT_NOTIFICATIONS_PER_PAGE = 15;
export const DEFAULT_FETCH_NOTIFICATIONS_INTERVAL = 60000;

// Series.
export const FIRST_10_DIGITS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const FIRST_100_DIGITS = [...Array(100).keys()];

// Pagination.
export const DEFAULT_PAGINATION_OVERFLOW = 1;

// Components.
export const DEFAULT_PROGRESS_VALUE = 0;

// Inputs.
export const DEFAULT_LIMIT_NAME = 40;
export const DEFAULT_LIMIT_TITLES = 80;
export const DEFAULT_LIMIT_DESCRIPTIONS = 255;
