import React from "react";

import ContentBuilderChaptersProvider from "./content-builder-chapters-provider";
import ContentBuilderChaptersView from "./content-builder-chapters-view";

export default function ContentBuilderChapters() {
  return (
    <ContentBuilderChaptersProvider>
      {(props) => <ContentBuilderChaptersView {...props} />}
    </ContentBuilderChaptersProvider>
  );
}
