import React from "react";

import { ChaptersProviderControllers } from "./content-builder-chapters-provider";
import { WrapperLayout } from "layout";
import { Loader, PageHeader, SassyAlert, SassySelect } from "components";
import { tutorRoutes } from "router/routes";
import { Button, Col, Container, Row } from "reactstrap";
import { wrapperStyles } from "constants/config";
import { SassyCard } from "components/cards";
import ListChaptersModal from "./components/list-chapters-modal";
import { ContentBuilderChapter } from ".";
import ChapterFormModal from "./components/chapter-form-modal";
import { SelectOption } from "constants/";
import useToast from "hooks/use-toast";
import { ConfirmDeletionModal } from "modules/modals/confirmation";
import { SimpleModalBody } from "modules/modals/components/modal-body/variants";
import { DeletionModalFooter } from "modules/modals/components/modal-footer/variants";
import { messages } from "constants/strings";

export default function ContentBuilderChaptersView({
  chapterHandlers,
  chaptersBag,
  materialContentBag,
  onContinue,
}: ChaptersProviderControllers): JSX.Element {
  const toast = useToast();
  // as the modals are only needed here. so I put the modals state here instead of the view model
  const [modalsState, setModalsState] = React.useState<{
    chaptersModal: boolean;
    chapterFormModal: {
      isOpen: boolean;
      action: "add" | "edit";
      chapter: ContentBuilderChapter | null;
    };
    deleteChapterConfirmationDialog: {
      isOpen: boolean;
      chapterId: number | undefined;
    };
  }>({
    chaptersModal: false,
    chapterFormModal: {
      isOpen: false,
      action: "add",
      chapter: null,
    },
    deleteChapterConfirmationDialog: {
      isOpen: false,
      chapterId: undefined,
    },
  });

  console.log({
    chaptersBag,
  });

  return (
    <WrapperLayout>
      <WrapperLayout.Main>
        {/* @ts-ignore */}
        <PageHeader
          title="Organize your Learning Material (Optional) 🙌"
          subTitle="Add chapters to your learning material to make it more organized and easy to understand."
          routes={[
            {
              name: "Dashboard",
              path: "/",
            },
            {
              name: "Materials",
              path: tutorRoutes.materials.url,
            },
            {
              active: true,
              name: "Organize",
            },
          ]}
        />

        <Container fluid className={wrapperStyles.p}>
          <SassyCard>
            <SassyCard.Body>
              <>
                <div className="d-flex justify-content-between">
                  <SassyCard.Title>Assign chapters to content</SassyCard.Title>
                  <Button
                    color="secondary"
                    onClick={() =>
                      setModalsState((prevState) => ({
                        ...prevState,
                        chaptersModal: true,
                      }))
                    }
                  >
                    Manage Chapters
                  </Button>
                </div>

                <div className="my-3">
                  {materialContentBag.requestState === "loading" ? (
                    <Loader isInline />
                  ) : materialContentBag.requestState === "erred" ? (
                    <SassyAlert
                      title="Error"
                      message="There was an error while fetching your learning material's content."
                      color="danger"
                    />
                  ) : materialContentBag.requestState === "loaded" &&
                    materialContentBag.content.length === 0 ? (
                    <SassyAlert
                      title="No Content"
                      message="You have not added any content to your learning material yet."
                      color="warning"
                    />
                  ) : (
                    materialContentBag.content.map((content, index) => {
                      return (
                        <Row className="border border-2 p-2 w-100 mx-0 mb-3 rounded">
                          <Col sm="6" md="12" lg="8">
                            <h3>
                              {index + 1}. {content.title}
                            </h3>
                            <p className="text-truncate-n1">
                              {content.description}
                            </p>
                          </Col>
                          <Col sm="6" md="8" lg="4">
                            {/* a options list for choosing chapter */}
                            <div className="d-flex gap-2 align-items-center">
                              <h3 className="mb-0">Chapter:</h3>

                              <SassySelect
                                className="w-100"
                                placeholder="Select Chapter"
                                isMulti={false}
                                closeMenuOnSelect={true}
                                options={[
                                  {
                                    label: "Select Chapter",
                                    value: "",
                                  },
                                  ...chaptersBag.chapters.map((chapter) => ({
                                    label: chapter.title,
                                    value: chapter.id,
                                  })),
                                ]}
                                selectedOptions={
                                  content.chapter_id
                                    ? {
                                        label: chaptersBag.chapters.find(
                                          (chapter) =>
                                            chapter.id === content.chapter_id
                                        )?.title,
                                        value: content.chapter_id,
                                      }
                                    : {
                                        label: "Select Chapter",
                                        value: "",
                                      }
                                }
                                onChange={(selectedOption: SelectOption) => {
                                  chapterHandlers.manageChapterContent({
                                    content_id: content.id,
                                    chapter_id: selectedOption.value
                                      ? selectedOption.value
                                      : content.chapter_id,
                                    action: !selectedOption.value
                                      ? "delete"
                                      : "add",
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      );
                    })
                  )}
                </div>

                <div className="d-flex justify-content-end">
                  <Button color="secondary" onClick={onContinue}>
                    Continue
                  </Button>
                </div>
              </>
            </SassyCard.Body>
          </SassyCard>

          {/*
           ------------ Chapters CRUD Modals
            - Chapters Modal
            - Add Chapter Modal
            - Edit Chapter Modal
            - Delete Chapter Confirmation Dialog
           */}

          <ListChaptersModal
            isOpen={modalsState.chaptersModal}
            onClose={() =>
              setModalsState((prevState) => ({
                ...prevState,
                chaptersModal: false,
              }))
            }
            chaptersBag={chaptersBag}
            onAddChapterListener={() =>
              setModalsState((prevState) => ({
                ...prevState,
                chaptersModal: false,
                chapterFormModal: {
                  isOpen: true,
                  action: "add",
                  chapter: null,
                },
              }))
            }
            onEditChapterListener={(chapter) =>
              setModalsState((prevState) => ({
                ...prevState,
                chaptersModal: false,
                chapterFormModal: {
                  isOpen: true,
                  action: "edit",
                  chapter,
                },
              }))
            }
            onDeleteChapterListener={(chapterId) =>
              toast.render(
                <ConfirmDeletionModal
                  body={
                    <SimpleModalBody>
                      Are you sure you want to delete this chapter?
                    </SimpleModalBody>
                  }
                  footer={
                    <DeletionModalFooter
                      onCancel={() => toast.dismiss()}
                      onDelete={() => {
                        chapterHandlers.deleteChapter(chapterId);
                      }}
                    />
                  }
                />
              )
            }
          />

          <ChapterFormModal
            isOpen={modalsState.chapterFormModal.isOpen}
            onClose={() =>
              setModalsState((prevState) => ({
                ...prevState,
                chaptersModal: true,
                chapterFormModal: {
                  isOpen: false,
                  action: "add",
                  chapter: null,
                },
              }))
            }
            action={modalsState.chapterFormModal.action}
            initialValues={
              modalsState.chapterFormModal.action === "edit"
                ? modalsState.chapterFormModal.chapter
                : undefined
            }
            onSubmit={(values) => {
              if (modalsState.chapterFormModal.action === "add") {
                // @ts-ignore

                chapterHandlers.createChapter({
                  // @ts-ignore
                  values,
                  onSuccess: () => {
                    setModalsState((prevState) => ({
                      ...prevState,
                      chaptersModal: true,
                      chapterFormModal: {
                        isOpen: false,
                        action: "add",
                        chapter: null,
                      },
                    }));
                  },
                });
              } else {
                // @ts-ignore
                chapterHandlers.updateChapter({
                  // @ts-ignore
                  values,
                  onSuccess: () => {
                    setModalsState((prevState) => ({
                      ...prevState,
                      chaptersModal: true,
                      chapterFormModal: {
                        isOpen: false,
                        action: "add",
                        chapter: null,
                      },
                    }));
                  },
                });
              }
            }}
          />
        </Container>
      </WrapperLayout.Main>
    </WrapperLayout>
  );
}
