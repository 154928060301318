import React from "react";
import { IconPlus, IconTrash } from "@tabler/icons";
import { Col, Row } from "reactstrap";
import { FormErrorMessage, IconButton } from "components";
import TimeDropdown from "components/select/time-dropdown";
import { convertTimeToReactSelectFormate } from "utils";
import {
  disable12AmEndTimeHours,
  disable12AmEndTimePeriod,
  emptyItem,
  hours,
  minutes,
  period,
} from "constants/dummyJson";
import { DEFAULT_MAX_AVAILABILITY_SLOTS_PER_DAY } from "constants";
import useToast from "hooks/use-toast";

function TutorTutoringAvailabilityField({
  state,
  onChangeTime,
  showErrorMessages,
  onChangeActiveState,
}) {
  const toast = useToast();

  // Data members.
  const { av_id, name, times, isActive, errors } = state;
  const [availabilityTime, setAvailabilityTime] = React.useState([]);

  //* This will first convert the dates to separate hours,minutes and AM,PM
  // * from '11:00:00' to hour: 11, minute:00 and check for am/pm
  React.useEffect(() => {
    //* Convert the date to separate hours,minutes and AM,PM
    const timesArray = [...times];
    const convertedTime = convertTimeToReactSelectFormate(timesArray);

    //* Set a new state to lopp over and display date
    setAvailabilityTime(convertedTime);
  }, [times]);

  // Handlers
  // function onChangeListener(index, label, value) {
  //   if (!isActive) return;

  //   const newTimes = times.slice();
  //   newTimes[index] = { ...newTimes[index], [key]: value };
  //   onChangeTime(newTimes);
  // }

  const onChangeHandler = (e, index, key, subKey) => {
    const { label, value } = e;
    const newTimes = [...availabilityTime];
    newTimes[index][key][subKey].label = label;
    newTimes[index][key][subKey].value = value;
    //* Send the updated date to update the origional array
    onChangeTime(newTimes);
  };

  function onAddListener() {
    // decreasing by one because the per day availability has one item set by default.
    if (availabilityTime.length <= DEFAULT_MAX_AVAILABILITY_SLOTS_PER_DAY - 1)
      onChangeTime([
        ...availabilityTime,
        { startTime: emptyItem, endTime: emptyItem },
      ]);
    else
      toast.info(
        `You can only have a maximum of ${DEFAULT_MAX_AVAILABILITY_SLOTS_PER_DAY} availability slots per day`
      );
  }

  function onRemoveListener(index) {
    const newTimes = availabilityTime.slice();
    newTimes.splice(index, 1);
    onChangeTime(newTimes);
  }

  return (
    <Row className="g-3 g-md-2 align-items-center align-items-md-start position-relative">
      {/* Checkbox */}
      <Col xs={6} md={3}>
        <div className="form-check form-check-success">
          <input
            id={av_id}
            type="checkbox"
            checked={isActive}
            data-tourstep="timeSlotCheckbox"
            className="form-check-input font-size-13"
            onChange={() => onChangeActiveState(!isActive)}
          />
          <label className="form-check-label fs-7" htmlFor={av_id}>
            {name}
          </label>
        </div>
      </Col>
      {/* Add button */}
      <Col xs={6} md={1} className="text-end text-md-start order-md-3">
        <IconButton
          type="button"
          variant="outline-success"
          tooltipPosition="left"
          onClick={onAddListener}
          aria-label="Add another"
          data-tourstep="addTimeSlotButton"
        >
          <IconPlus size={18} />
        </IconButton>
      </Col>
      {/* Grid */}
      <Col xs={12} md={8} className="d-flex flex-column gap-3">
        {availabilityTime.map((time, index) => {
          return (
            <div key={index} className="d-flex flex-column gap-1">
              <Row className="g-2 flex-md-nowrap">
                {/* Remove button */}
                {!!index && (
                  <Col
                    xs={12}
                    md={1}
                    className="order-md-3 text-end text-md-start"
                  >
                    <IconButton
                      type="button"
                      aria-label="Remove"
                      variant="outline-danger"
                      tooltipPosition="left"
                      onClick={() => onRemoveListener(index)}
                    >
                      <IconTrash size={18} />
                    </IconButton>
                  </Col>
                )}
                {/* Start Time */}
                <Col xs={12} md={6}>
                  <Row className="g-2 align-items-center">
                    <Col xs={4} className="d-md-none">
                      <label htmlFor={`startTimeField${av_id}`}>
                        Start Time
                      </label>
                    </Col>
                    <Col xs={8} md={12}>
                      <div className="hstack gap-1">
                        {/* Custom drodown component for handling dates separately */}
                        <TimeDropdown
                          index={index}
                          onChange={onChangeHandler}
                          selectedValue={time.startTime.hour}
                          values={hours}
                          name="startTime"
                          subKey="hour"
                          disabled={!isActive}
                          id={`startTimeField${av_id}`}
                          color="#d6f3e9"
                        />
                        :
                        <TimeDropdown
                          index={index}
                          onChange={onChangeHandler}
                          selectedValue={time.startTime.minute}
                          values={minutes}
                          name="startTime"
                          subKey="minute"
                          disabled={!isActive}
                          id={`startTimeField${av_id}`}
                          color="#d6f3e9"
                        />
                        <TimeDropdown
                          index={index}
                          onChange={onChangeHandler}
                          selectedValue={time.startTime.period}
                          values={period}
                          subKey="period"
                          name="startTime"
                          disabled={!isActive}
                          id={`startTimeField${av_id}`}
                          color="#d6f3e9"
                        />
                      </div>

                      {/* Previous method with one input field */}
                      {/* <Input
                        type="time"
                        className="fs-7"
                        step="900"
                        disabled={!isActive}
                        value={time.startTime}
                        id={`startTimeField${av_id}`}
                        onChange={(e) =>
                          onChangeListener(index, "startTime", e.target.value)
                        }
                      /> */}
                    </Col>
                  </Row>
                </Col>
                {/* End Time */}
                <Col xs={12} md={6}>
                  <Row className="g-2 align-items-center">
                    <Col xs={4} className="d-md-none">
                      <label htmlFor={`endTimeField${av_id}`}>End Time</label>
                    </Col>
                    <Col xs={8} md={12}>
                      <div className="hstack gap-1">
                        {/* Custom drodown component for handling dates separately */}
                        <TimeDropdown
                          index={index}
                          onChange={onChangeHandler}
                          selectedValue={time.endTime.hour}
                          values={
                            time.startTime.period.value === "PM"
                              ? disable12AmEndTimeHours
                              : hours
                          }
                          name="endTime"
                          subKey="hour"
                          disabled={!isActive}
                          id={`startTimeField${av_id}`}
                          color="#d6f3e9"
                        />
                        :
                        <TimeDropdown
                          index={index}
                          onChange={onChangeHandler}
                          selectedValue={time.endTime.minute}
                          values={minutes}
                          name="endTime"
                          subKey="minute"
                          disabled={!isActive}
                          id={`startTimeField${av_id}`}
                          color="#d6f3e9"
                        />
                        <TimeDropdown
                          index={index}
                          onChange={onChangeHandler}
                          selectedValue={time.endTime.period}
                          values={
                            time.endTime.hour.value >= "12"
                              ? disable12AmEndTimePeriod
                              : period
                          }
                          subKey="period"
                          name="endTime"
                          disabled={!isActive}
                          id={`startTimeField${av_id}`}
                          color="#d6f3e9"
                        />
                      </div>
                      {/* <Input
                        type="time"
                        className="fs-7"
                        min={time.startTime}
                        value={time.endTime}
                        disabled={!isActive}
                        id={`endTimeField${av_id}`}
                        onChange={(e) =>
                          onChangeListener(index, "endTime", e.target.value)
                        }
                      /> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Error messages. */}
              {showErrorMessages && errors[index]?.length > 0 && (
                <Row className="g-2">
                  {errors[index].map((error) => (
                    <Col xs={12} key={error}>
                      <FormErrorMessage message={error} />
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          );
        })}
      </Col>
    </Row>
  );
}

export default TutorTutoringAvailabilityField;
