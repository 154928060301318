/* eslint-disable eqeqeq */
import React, { useMemo } from "react";

// Utils.
import { useApiEffect } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_LEARNING_MATERIALS_PER_PAGE } from "constants";
import {
  deleteLearningMaterial,
  fetchTutorLearningMaterial,
  toggleSearchableListener,
} from "store/store-content-builder";

// Components.
import { TabPane, TabContent } from "reactstrap";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { Loader, TabBar, Pagination } from "components";
import { ListWrapper } from "components/lists";
import { ListItemMaterial, ListItemWrapper } from "components/list-items";
import { getSemanticHeading } from "utils";
import { tutorRoutes } from "router/routes";
import { useContentBuildingHelper } from "hooks/content-builder";
import { useStaticDataV3 } from "hooks/static-data";
import useToast from "hooks/use-toast";
import { LEARNING_MATERIAL_DETAILS_URL } from "constants/";

/**
 * A module that lists all the learning materials of a tutor in the form of tabs.
 * @param {Object} object
 * @param {boolean | undefined} object.allowSearch
 * Default = false, whether to allow the user to search through the materials or not.
 * @param {boolean | undefined} object.allowPagination
 * Default = false, whether to allow the user to paginate over the materials or not.
 *
 * @param {HeadingLevel} object.headingTag The semantic level of heading to use.
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function MaterialsTutor({
  allowSearch = false,
  allowPagination = false,
  headingTag: Heading = "div",
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const tab = useMatch(tutorRoutes.publishedMaterials.url)
    ? tutorRoutes.publishedMaterials.url
    : tutorRoutes.draftMaterials.url;

  const { getStaticData, ...staticData } = useStaticDataV3([
    "grades",
    "subjects",
    "curriculums",
  ]);

  const { setIsPublishable, resetHelper, setContentAndMoveStep } =
    useContentBuildingHelper();

  /**
   * @type {LearningMaterialState}
   */
  const { data, page_no, number_of_pages, state } = useSelector(
    (state) => state.contentBuilder.learningMaterial
  );

  const { list: learningMaterial, total_drafted, total_published } = data;

  /**
   *  Re-fetches the learning material list.
   */
  function reFetch(proposal) {
    dispatch({
      type: "LOADING_LEARNING_MATERIAL",
    });

    proposal["status"] =
      {
        [tutorRoutes.draftMaterials.url]: "drafted",
        [tutorRoutes.publishedMaterials.url]: "published",
      }[tab] || "published";

    dispatch(fetchTutorLearningMaterial(proposal));
  }

  useApiEffect(() => {
    reFetch({});
  }, [tab]);

  /**
   *
   * @param  key
   * @param  data
   * @param {import("constants/types/types-static-data").StaticDataV3Key} staticKey
   * @returns
   */
  function getStaticRecord(key, data, staticKey) {
    if (!Array.isArray(data)) return "";

    const record = data[0];

    if (!record[key]) return "";

    return getStaticData(record[key], staticData[staticKey].data);
  }

  const onChangePage = (newPage) => {
    reFetch({ page: newPage });
  };

  /* Material Actions Listeners */

  // Material Editing Listener
  const onEditMaterial = (index) => {
    resetHelper();
    // setting up the learning content in the content building helper so that it can be used in the content builder
    const content = learningMaterial[index];

    // setting up the current step in the content building helper so that it can be used in the content builder
    setContentAndMoveStep(content, content.content_uploaded === 1 ? 4 : 1);
    setIsPublishable(content.content_uploaded === 1);

    navigate(
      `${tutorRoutes.createMaterials.url}?id=${learningMaterial[index].id}`,
      {
        state: learningMaterial[index],
      }
    );
  };

  // Material Deletion Listener
  const onDeleteMaterial = (index) => {
    const content = learningMaterial[index];

    toast.loading();

    dispatch(
      deleteLearningMaterial({
        id: content.id,
        onSuccess: (res) => {
          reFetch({ page: page_no });
          toast.success();
          console.log({ res });
        },
        onFailure: (error) => {
          toast.error(error?.message || "");
          console.log({ error });
        },
      })
    );
  };

  // const onCloneMaterial = (index) => {
  //   toast.info("Under Development Feature. Coming Soon");
  // };

  const onToggleSearchableMaterial = (index) => {
    const content = learningMaterial[index];
    let visibility = content.lm_visibility === 0 ? 1 : 0;
    const data = { lm_id: content.id, visibility };
    toast.loading();

    dispatch(
      toggleSearchableListener({
        data,
        onSuccess: (res) => {
          reFetch({ page: page_no });
          toast.success();
          console.log({ res });
        },
        onFailure: (error) => {
          toast.error(error?.message || "");
          console.log({ error });
        },
      })
    );
  };

  const onViewMaterial = (id) => {
    // Encode the id to Base64
    const encodedId = btoa(unescape(encodeURIComponent(id)));
    const targetURL = `${LEARNING_MATERIAL_DETAILS_URL}/${encodedId}`;
    window.open(targetURL, "_blank");
  };

  const publishedMaterial = useMemo(
    () => learningMaterial.filter(({ published }) => published == 1),
    [learningMaterial]
  );

  const draftedMaterial = useMemo(
    () => learningMaterial.filter(({ published }) => published == 0),
    [learningMaterial]
  );

  /**
   * @param {Object} object
   * @param {LearningMaterial[]} object.material
   * @returns {JSX.Element}
   */
  const ListOfMaterial = ({ label, material, totalItems }) => (
    <div className="vstack gap-3 maxw-xl align-items-center">
      {allowSearch && (
        <div className="d-flex justify-content-between align-items-center mx-3 w-100">
          <Heading className="h5 fw-bold">
            {totalItems || 0} Material{totalItems > 1 ? "s" : ""} {label}
          </Heading>
          <Link
            className="btn btn-secondary"
            to={tutorRoutes.createMaterials.url}
            onClick={() => {
              resetHelper();
              setContentAndMoveStep({}, 0);
            }}
          >
            Create Learning Material
          </Link>
        </div>
      )}
      {state === "erred" ? (
        <div className="alert alert-danger">
          We have encountered an issue while loading the materials. Please try
          again.
        </div>
      ) : state === "loading" ? (
        <Loader isInline />
      ) : material.length === 0 ? (
        <div className="alert alert-success">
          Nothing found within this category.
        </div>
      ) : (
        <>
          <ListWrapper className="w-100 bg-light">
            {material.map((mat, index) => (
              <ListItemWrapper key={index}>
                <ListItemMaterial
                  {...mat}
                  headingTag={getSemanticHeading(Heading)}
                  grade={getStaticRecord("grade_id", mat.grades, "grades")}
                  subject={getStaticRecord(
                    "subject_id",
                    mat.subjects,
                    "subjects"
                  )}
                  lmVisibility={mat.lm_visibility}
                  curriculum={getStaticRecord(
                    "curriculum_id",
                    mat.curriculums,
                    "curriculums"
                  )}
                  {...(label === "drafted"
                    ? {
                        // props for only drafted materials
                        allowStats: false,
                        editButtonListener: () => onEditMaterial(index),
                        deleteButtonListener: () => onDeleteMaterial(index),
                      }
                    : {})}
                  {...(label === "published"
                    ? {
                        viewButtonListener: () => onViewMaterial(mat.id),
                        toggleSearchableListener: () =>
                          onToggleSearchableMaterial(index),
                        // cloneButtonListener: () => onCloneMaterial(index),
                      }
                    : {})}
                />
              </ListItemWrapper>
            ))}
          </ListWrapper>
          {allowPagination ? (
            material.length > 0 && (
              <Pagination
                type="pages"
                className="w-100"
                currentPage={parseInt(page_no)}
                onChangePage={onChangePage}
                itemsPerPage={DEFAULT_LEARNING_MATERIALS_PER_PAGE}
                totalItems={
                  DEFAULT_LEARNING_MATERIALS_PER_PAGE * number_of_pages
                }
              />
            )
          ) : (
            <div>
              <Link
                to={tutorRoutes.publishedMaterials.url}
                className="btn btn-secondary"
              >
                View All
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <article className="mt-3 mt-md-0">
      <TabBar>
        <TabBar.Item to={tutorRoutes.publishedMaterials.url}>
          <div>Published</div>
        </TabBar.Item>
        <TabBar.Item to={tutorRoutes.draftMaterials.url} tabNumber={3}>
          <div>Drafts</div>
        </TabBar.Item>
      </TabBar>
      <TabContent activeTab={tab} className="p-3 p-md-4">
        <TabPane tabId={tutorRoutes.publishedMaterials.url}>
          <ListOfMaterial
            label="published"
            totalItems={total_published}
            material={publishedMaterial}
          />
        </TabPane>
        <TabPane tabId={tutorRoutes.draftMaterials.url}>
          <ListOfMaterial
            label="drafted"
            totalItems={total_drafted}
            material={draftedMaterial}
          />
        </TabPane>
      </TabContent>
    </article>
  );
}

export default MaterialsTutor;
