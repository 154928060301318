import React, { useState } from "react";
import moment, { Moment } from "moment";
import DatePicker from "components/date-picker";
import { DUMMIES_MONTHS_NUMERIC } from "constants/dummies";

type Props = {
  id?: string;
  value: string;
  max?: Moment;
  aboveMaxErrorMessage?: string;
  showErrorMessage?: boolean;
  onBlur?: () => unknown;
  onChange: (value: string) => unknown;
  onChangeValidation?: (isValid: boolean) => unknown;
};

/**
 * DatePicketField wraps the DatePicker component and handles the logic of
 * updating the value of the DatePicker component.
 *
 * @version 0.1.2
 * @changelog
 * 0.1.4 - Added showErrorMessage prop to DatePicker component.
 * 0.1.3 - Added onChangeValidation prop to DatePicker component.
 * 0.1.2 - Added aboveMaxErrorMessage prop to DatePicker component.
 * 0.1.1 - Added max prop to DatePicker component.
 * 0.1.0 - Initial implementation.
 *
 * @todo The error message is not gonna work until we pass isValid prop to the DatePicker component. This validation flow is not
 * okay and should be renewed.
 */
export default function DatePickerField({
  id,
  max,
  value,
  showErrorMessage = false,
  aboveMaxErrorMessage,
  onBlur,
  onChange,
  onChangeValidation,
}: Props) {
  const date = new Date();

  // the DatePicker component sets the correct value on initial render by useEffect hook.
  const [isValidDateSelection, setIsValidSelection] = useState(true);

  const [selectedDay, setSelectedDay] = React.useState({
    label: value ? value.split("-")[2] : "1",
    value: value ? value.split("-")[2] : "1",
  });

  const [selectedMonth, setSelectedMonth] = React.useState(
    DUMMIES_MONTHS_NUMERIC.filter(
      (m) => m.value === parseInt(value.split("-")[1])
    )[0] || {
      label: "January",
      value: 1,
    }
  );

  const [selectedYear, setSelectedYear] = React.useState({
    label: value ? value.split("-")[0] : date.getFullYear(),
    value: value ? value.split("-")[0] : date.getFullYear(),
  });

  // Abdullah knows whatever the fuck is happening here.
  // I didn't have time so I copied his shit and made it better.
  const handleDayChange = (val: any) => {
    if (!val || !val.value) return;

    setSelectedDay(val);
    onChange(
      `${selectedYear?.value}-${("0" + selectedMonth.value).slice(-2)}-${(
        "0" + val.value
      ).slice(-2)}`
    );
  };

  const handleMonthChange = (val: any) => {
    if (!val || !val.value) return;

    setSelectedMonth(val);
    onChange(
      `${selectedYear?.value}-${("0" + val.value).slice(-2)}-${(
        "0" + selectedDay?.value
      ).slice(-2)}`
    );
  };

  const handleYearChange = (val: any) => {
    if (!val || !val.value) return;

    setSelectedYear(val);
    onChange(
      `${val.value}-${("0" + selectedMonth.value).slice(-2)}-${(
        "0" + selectedDay?.value
      ).slice(-2)}`
    );
  };

  return (
    <DatePicker
      selectedDate={value}
      // @ts-ignore
      max={max || moment().subtract(1, "days")}
      validationErrorMessage={aboveMaxErrorMessage}
      showErrorMessage={showErrorMessage}
      isValidSelection={isValidDateSelection}
      setValidation={(isValid) => {
        onChangeValidation?.(isValid);
        setIsValidSelection(isValid);
      }}
    >
      {[
        // @ts-ignore
        <DatePicker.Day
          id={id}
          className="flex-grow-1"
          value={selectedDay}
          onBlur={onBlur}
          onChange={handleDayChange}
        />,
        // @ts-ignore
        <DatePicker.Month
          className="flex-grow-1"
          value={selectedMonth}
          onBlur={onBlur}
          onChange={handleMonthChange}
        />,
        // @ts-ignore
        <DatePicker.Year
          className="flex-grow-1"
          value={selectedYear}
          onBlur={onBlur}
          onChange={handleYearChange}
        />,
      ]}
    </DatePicker>
  );
}
