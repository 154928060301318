import { useState } from "react";
import { Row, Col, Button, Modal, ModalBody } from "reactstrap";
import { MyModalHeader } from "./";
import { Rating } from "@smastrom/react-rating";
import { connect } from "react-redux";
import { addSessionReview } from "store";
import ToastFactory from "utils/toast-factory";
import { requirements } from "constants/strings";
import { ratingStarStyles } from "constants/config";

function RateSessionModal({
  isOpen,
  toggleModal,
  session_id,
  addSessionReview,
  afterSuccess,
  tutorName = "tutor",
}) {
  const toastFactory = ToastFactory();
  const [rate, setRate] = useState(0);
  const [experience, setExperience] = useState(null);
  const [skill, setSkill] = useState(null);
  const [review, setReview] = useState("");

  const clearStates = () => {
    setRate(0);
    setExperience(null);
    setSkill(null);
    setReview("");
  };

  const handleSubmit = () => {
    // validating
    if (!rate || !experience || !skill || !review) {
      toastFactory.error(requirements.ALL);
    } else {
      // adding review
      addSessionReview({
        experience,
        skill,
        rate,
        review,
        session_id,
        onSuccess: (res) => {
          toastFactory.dismiss();
          afterSuccess?.();
        },
        onFailure: (error) => {
          toastFactory.error(error.message);
        },
      });
      // clearing all states
      clearStates();
      // closing modal
      toggleModal();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      className="text-secondary"
      size="md"
    >
      <ModalBody className="p-3 text-secondary">
        <MyModalHeader title="Review the session" toggleModal={toggleModal} />
        <p className="mt-0">Help us to build better place for you.</p>
        <main className="main">
          <Row className="w-100 m-0">
            <Col xs="12" sm="6" className="p-0">
              <h1 className="h5 my-3 text-secondary">
                How was the experience with {tutorName}?
              </h1>
              <div className="gap-row-sm">
                <Button
                  outline={experience !== 1}
                  onClick={() => setExperience(1)}
                  active={experience === 1}
                >
                  Great
                </Button>
                <Button
                  outline={experience !== 2}
                  onClick={() => setExperience(2)}
                  active={experience === 2}
                >
                  Good
                </Button>
                <Button
                  outline={experience !== 3}
                  onClick={() => setExperience(3)}
                  active={experience === 3}
                >
                  Bad
                </Button>
              </div>
            </Col>
            <Col xs="12" sm="6" className="p-0">
              <h1 className="h5 my-3 text-secondary">
                How you rate his teaching skills?
              </h1>
              <div className="gap-row-sm">
                <Button
                  outline={skill !== 1}
                  onClick={() => setSkill(1)}
                  active={skill === 1}
                >
                  Bad
                </Button>
                <Button
                  outline={skill !== 2}
                  onClick={() => setSkill(2)}
                  active={skill === 2}
                >
                  Good
                </Button>
                <Button
                  outline={skill !== 3}
                  onClick={() => setSkill(3)}
                  active={skill === 3}
                >
                  Great
                </Button>
              </div>
            </Col>
          </Row>
          <div className="my-3">
            <h1 className="h5 text-secondary">Rate Session</h1>

            <Rating
              style={{ maxWidth: 250, maxHeight: 35 }}
              value={rate}
              itemStyles={ratingStarStyles}
              onChange={(newRating) => setRate(newRating)}
            />
          </div>
          <div className="my-3">
            <h1 className="h5 text-secondary">Review</h1>
            <textarea
              name="review"
              id="review"
              cols="30"
              rows="10"
              className="form-control form-control-noicon"
              placeholder="Type your review"
              value={review}
              onChange={(e) => setReview(e.target.value)}
            ></textarea>
          </div>
        </main>

        <footer className="mt-3">
          <Button
            color="secondary"
            style={{ minWidth: "5rem" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </footer>
      </ModalBody>
    </Modal>
  );
}

export default connect((state) => ({}), { addSessionReview })(RateSessionModal);
