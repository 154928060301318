import * as React from "react";
import moment, { Moment } from "moment";
import DayPicker from "./day-picker";
import MonthPicker from "./month-picker";
import YearPicker from "./year-picker";

import styles from "./date-picker.module.scss";
import { messages } from "constants/strings";
/**
 *  DataPicker is a wrapper for wrapping DayPicker, MonthPicker and Year Picker elements and returns 3 Select elements in responsive form.
 */

type IDatePickerProps = React.ComponentPropsWithoutRef<"div"> & {
  children: JSX.Element[];
  max?: Date;
  min?: Date;
  selectedDate?: string;
  isValidSelection?: boolean;
  validationErrorMessage?: string;
  showErrorMessage?: boolean;
  setValidation?: (result: boolean) => void;
};

function DatePicker({
  children,
  max = moment().toDate(),
  min,
  selectedDate,
  isValidSelection,
  validationErrorMessage,
  showErrorMessage = true,
  setValidation,
  ...props
}: IDatePickerProps) {
  const [DaySelect, MonthSelect, YearSelect] = children;

  React.useEffect(() => {
    if (selectedDate?.split("-").length === 3) {
      setValidation?.(moment(selectedDate).isSameOrBefore(max));
    }
  }, [selectedDate]);

  return (
    <div {...props} data-testclass="datePickerParent">
      <div className={styles["date-picker"]}>
        <div className={styles["day-picker"]}>{DaySelect}</div>
        <div className={styles["month-picker"]}>{MonthSelect}</div>
        <div className={styles["year-picker"]}>{YearSelect}</div>
      </div>
      {showErrorMessage && !isValidSelection && (
        <p className="srv-validation-message mt-1">
          {validationErrorMessage || messages.NOT_VALID_DATE}
        </p>
      )}
    </div>
  );
}

export default Object.assign(DatePicker, {
  Day: DayPicker,
  Month: MonthPicker,
  Year: YearPicker,
});
