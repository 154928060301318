import * as Yup from "yup";
import { RemoteProfile } from "constants/types";
import { getEnvInt } from "utils/utils-env";

export const nameSchema = Yup.string()
  .matches(
    /^[a-zA-Z .]+$/,
    "Full Name can only contain alphabets, spaces and dots"
  )
  .required("Full Name is required");

export const dobSchema = Yup.date()
  .required("Date of Birth is required")
  .max(
    (() => {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 14);
      console.log({ date });
      return date;
    })(),
    "You must be 14 years old to continue."
  );

export const timezoneSchema = Yup.object({
  value: Yup.string().required("Timezone is required"),
});

export const titleSchema = Yup.string().required("Profile Title is required");

export const languagesSchema = Yup.array()
  .of(Yup.object())
  .min(1, "At least one Language is required");

export const mobileNumberSchema = Yup.object({
  number: Yup.string()
    .required("Mobile Number is required")
    .min(7, "Number should have more than 7 digits")
    .max(15, "Number should have less than 15 digits"),
});

const maxLength = getEnvInt("MAX_BIO_CHARACTERS");
export const bioSchema = Yup.string()
  .required("Bio is required")
  .min(100, "Bio must be at least 100 characters")
  .max(maxLength, `Bio must be less than ${maxLength} characters`);

export const profileSchema = new Map<keyof RemoteProfile, unknown>([
  ["dob", dobSchema],
  ["bio", bioSchema],
  ["name", nameSchema],
  ["title", titleSchema],
  ["timezone", timezoneSchema],
  ["languages", languagesSchema],
  ["mobile_number", mobileNumberSchema],
]);
